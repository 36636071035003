//var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/ToastNotification.js
import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastNotification = ({ showToast, toastMessage, toastVariant, setShowToast }) => {
  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast
        bg={toastVariant}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotification;
