// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/PriceOptions.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const PriceOptions = ({
  priceOptions,
  handlePriceOptionChange,
  addPriceOption,
  setPriceOptions,
  removePriceOption,
  statusOptions,
}) => {

  
  // ฟังก์ชันสำหรับลบ price option ที่มี PriceID
  const deletePriceOptionAPI = async (priceID) => {
    try {
      const response = await fetch('https://py.testproject.work/price/api/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ "PriceID": [priceID] }), // ส่ง PriceID ในรูปแบบที่ API ต้องการ
      });

      if (!response.ok) {
        throw new Error('Failed to delete the price option');
      }

      // ถ้าลบข้อมูลสำเร็จจาก API, จึงลบในสถานะฝั่ง client
      setPriceOptions(prevOptions => prevOptions.filter(option => option.PriceID !== priceID));
    } catch (error) {
      console.error('Error deleting price option:', error);
    }
  };

  // ตรวจสอบ PriceID ก่อนดำเนินการลบ
const handleDeleteOption = async (option, index) => {
  if (option.PriceID && option.PriceID !== 0) {
    deletePriceOptionAPI(option.PriceID);
  } else {
    removePriceOption(index); // ใช้ฟังก์ชัน removePriceOption สำหรับลบในสถานะฝั่ง client โดยตรง
  }
};


  return (
    <div className="mb-3">
      <label htmlFor="ServiceName" className="form-label">Price options:</label>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Actions</th>
            <th scope="col">Type</th>
            <th scope="col">Price Value</th>
            <th scope="col">Currency</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>


          {priceOptions.map((option, index) => (
            <tr key={index}>
               <th scope="row">
            <button
              type="button"
              className={`btn ${index === 0 ? 'btn-dark' : 'btn-danger'} btn-sm`}
              onClick={() => index === 0 ? addPriceOption() : handleDeleteOption(option, index)}
            >
              <FontAwesomeIcon icon={index === 0 ? faPlus : faTrash} />
            </button>
          </th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="PriceType"
                  value={option.PriceType}
                  onChange={e => handlePriceOptionChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="PriceValue"
                  value={option.PriceValue}
                  onChange={e => handlePriceOptionChange(index, e)}
                />
              </td>
              <td>
                <select
                  className="form-select"
                  name="currency"
                  value={option.currency}
                  onChange={e => handlePriceOptionChange(index, e)}
                >
                  <option value="EUR">EUR</option>
                  {/* Add other currency options here */}
                </select>
              </td>
              <td>
                <select
                  className="form-select"
                  name="status"
                  value={option.status}
                  onChange={e => handlePriceOptionChange(index, e)}
                >
                  <option value="">Select Status</option>
                  {statusOptions.map((statusOption) => (
                    <option key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}



        </tbody>
      </table>
    </div>
  );
};

export default PriceOptions;
