// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Member.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import MemberTable from '../components/MemberTable';
import MemberForm from '../components/MemberForm'; // Import MemberForm

import '../css/Member.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const Member = () => {

  const [memberData, setMemberData] = useState([]);
  const [currentMember, setCurrentMember] = useState(null); // For edit mode
  const [isFormVisible, setIsFormVisible] = useState(false); // To show/hide the form
  const [formMode, setFormMode] = useState('add'); // 'add' or 'edit'
  const navigate = useNavigate();
  const user_ID = '57'; // Assuming this is a constant or received from state/context

  const [searchQuery, setSearchQuery] = useState('');

  const handleCancelEdit = () => {
    // Logic to handle canceling the edit
    setIsFormVisible(false);
  };

  // Define handleEditMember here, outside of useEffect
  const handleEditMember = (member) => {
    console.log('Edit Member:', member);
    // Additional logic for handling member editing
    setCurrentMember(member);
    setFormMode('edit');
    setIsFormVisible(true);

  };

  const handleAddMember = () => {
    setCurrentMember(null);
    setFormMode('add');
    setIsFormVisible(true);
  };


  const fetchMemberData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://py.testproject.work/register/api/all_users?user_ID=${user_ID}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setMemberData(Array.isArray(data) ? data : [data]);

      console.log('fetchMemberData:', data);   
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  // Use useEffect to initially fetch the data
useEffect(() => {
  if (!localStorage.getItem('token')) {
    navigate('/');
    return;
  }

  fetchMemberData();
}, [navigate, user_ID]);

  const handleCancel = () => {
    setIsFormVisible(false);
  };

  const handleTableRefresh = useCallback(() => {
    fetchMemberData(); // Directly call fetchMemberData to refresh
  }, [user_ID]);



  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">

        <Header
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onBack={isFormVisible ? handleCancelEdit : undefined}
        />

        <div >
          {/* Add Member Button */}

          <div className="row">
            <div className="col-12 col-sm-12">

              <div className="member-table-container"> {/* คอนเทนเนอร์ที่มีเงา */}


                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-primary me-md-2 btn-sm" type="button">
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </button>

                  <button onClick={handleAddMember} className="btn btn-primary btn-sm" >
                    <FontAwesomeIcon icon={faPlus} /> Add Member
                  </button>
                </div>


                {/* Conditional Rendering of MemberForm */}
                {isFormVisible ? (
                  <MemberForm
                    memberData={currentMember}
                    mode={formMode}
                    onCancel={handleCancelEdit}
                    setIsFormVisible={setIsFormVisible} // Pass this function as a prop
                    onTableRefresh={handleTableRefresh}
                  />
                ) : (
                  <MemberTable memberData={memberData} onEditMember={handleEditMember} token={localStorage.getItem('token')} onTableRefresh={handleTableRefresh} />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
