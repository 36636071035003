// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/TypeServicesForm.js

import React, { useState } from 'react';
import '../css/TypeServicesForm.css'; // นำเข้า CSS ที่กำหนดเอง

const TypeServicesForm = ({ typeServiceData, mode, onSave, onCancel, statusOptions }) => {
  const [formData, setFormData] = useState({
    ServiceTypes_Name: typeServiceData?.ServiceTypes_Name || '',
    Description: typeServiceData?.Description || '',
    status: typeServiceData?.status || '',
    ServiceTypes_ID: typeServiceData?.ServiceTypes_ID
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // สร้างชื่อปุ่มที่เหมาะสมสำหรับ GEN-Z
  const submitButtonText = mode === 'edit' ? 'Go Edit' : 'Start Fresh';

  return (
    <div className="form-container shadow p-3 mb-5 bg-white rounded"> {/* กล่องที่มีเงา */}
      <form onSubmit={(e) => { e.preventDefault(); onSave(formData); }}>
        <div className="mb-3">
          <label htmlFor="ServiceTypes_Name" className="form-label">Service Type Name:</label>
          <input
            type="text"
            id="ServiceTypes_Name"
            name="ServiceTypes_Name"
            className="form-control"
            value={formData.ServiceTypes_Name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Description" className="form-label">Description:</label>
          <textarea
            id="Description"
            name="Description"
            className="form-control"
            value={formData.Description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">Status:</label>
          <select
            id="status"
            name="status"
            className="form-control"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="">Please Select Online Status</option>
            {statusOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-dark">
            {submitButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TypeServicesForm;
