// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Invoice.js

import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import PropTypes from 'prop-types';
import '../css/InvoiceComponent.scss';


const AdminInvoice = () => {

    const [language] = useState('EN');
    const [translations, setTranslations] = useState({});
    const [fixedRate, setFixedRate] = useState({});
    const [storeConstants, setStoreConstants] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [bookingDetailsExtended, setBookingDetailsExtended] = useState([]);

    const [totalAmount, setTotalAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);


    // Define your state with default values
    const [clientDetails, setClientDetails] = useState({
        name: 'Mirabeau thai spa',
        address: '10 RUE MIRABEAU 75016 PARIS',
        notes: 'IMPORTANT NOTES...',
    });



    const t = translations[language] || {};

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const translationsResp = await fetch('https://dev.afaa.website/s-293-spatime-dev/src/data/InvoiceDataTranslations.json');
                const translationsData = await translationsResp.json();
                setTranslations(translationsData);

                const fixedRateResp = await fetch('https://dev.afaa.website/s-293-spatime-dev/src/data/FixedRateConnAPI.json');
                const fixedRateData = await fixedRateResp.json();
                //console.log("Fixed Rate Data:", fixedRateData); // Inspect fixedRateData
                setFixedRate(fixedRateData);

                const storeConstantsResp = await fetch('https://dev.afaa.website/s-293-spatime-dev/src/data/StoreConstant.json');
                const storeConstantsData = await storeConstantsResp.json();
                setStoreConstants(storeConstantsData);
            } catch (error) {
                console.error("Fetching config data failed:", error);
            }
        };

        fetchConfigData();
    }, []);


    useEffect(() => {
        const fetchInvoices = async () => {
            const bookingID = localStorage.getItem('localBookingReduxID');
            const token = localStorage.getItem('token');

            try {
                // Fetching booking details
                const bookingResponse = await fetch(`https://py.testproject.work/booking/api/view?sort_by=BookingID&sort=desc&BookingID=${bookingID}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                //console.log('Booking Response received:', bookingResponse);
                if (!bookingResponse.ok) {
                    throw new Error(`HTTP error! status: ${bookingResponse.status}`);
                }

                const bookingData = await bookingResponse.json();
                //console.log('Booking Data:', bookingData);

                if (bookingData.length > 0) {
                    setBookingDetails(bookingData[0]);
                    // Convert string values to numbers as necessary
                    const fetchedTotalAmount = parseFloat(bookingData[0].TotalAmount);
                    const fetchedAmountPaid = parseFloat(bookingData[0].AmountPaid);

                    setTotalAmount(fetchedTotalAmount);
                    setAmountPaid(fetchedAmountPaid);


                    // Fetching customer details
                    const customerID = bookingData[0].CustomerID;
                    const customerResponse = await fetch(`https://py.testproject.work/register/api/user?user_ID=${customerID}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    //console.log('Customer Response:', customerResponse);

                    if (!customerResponse.ok) {
                        console.error('Customer response was not ok:', customerResponse.status);
                        // Optionally, you can throw an error here to stop the execution and catch it below
                        throw new Error(`HTTP error! status: ${customerResponse.status}`);
                    }

                    const customerData = await customerResponse.json();
                    //console.log('Customer Data:', customerData);
                    setCustomerDetails(customerData);

                    // Fetching extended booking details
                    const detailResponse = await fetch(`https://py.testproject.work/booking/api/detail_view?BookingID=${bookingID}`);
                    //console.log('Detail Response received:', detailResponse);
                    if (!detailResponse.ok) {
                        throw new Error(`Failed to fetch booking details with status: ${detailResponse.status}`);
                    }
                    const bookingDetailsJson = await detailResponse.json();
                    //console.log('Booking Details Json:', bookingDetailsJson);

                    const extendedDetails = await Promise.all(bookingDetailsJson.map(async (detail) => {
                        const priceResponse = await fetch(`https://py.testproject.work/price/api/price_service?PriceID=${detail.ServiceID}`);
                        //console.log('Price Response for ServiceID', detail.ServiceID, ':', priceResponse);
                        if (!priceResponse.ok) {
                            throw new Error(`Failed to fetch price details for ServiceID ${detail.ServiceID}`);
                        }
                        const priceJson = await priceResponse.json();
                        //console.log("Price details for ServiceID:", detail.ServiceID, priceJson);
                        return {
                            ...detail,
                            ServiceName: priceJson.results[0].ServiceName,
                            PriceType: priceJson.results[0].PriceType,
                            PriceValue: priceJson.results[0].PriceValue
                        };
                    }));

                    setBookingDetailsExtended(extendedDetails);

                    //const taxRate = fixedRate.tax || 0; // Use a default value of 0 if the tax rate is undefined
                    const tax = fetchedTotalAmount * (fixedRate.tax || 0) / 100;

                    //console.log('fixedRate.tax:', fixedRate.tax);
                    //console.log('tax:', tax);    
                }
            } catch (error) {
                console.error("Fetching invoices failed:", error);
            }
        };

        fetchInvoices();
    }, []); // Ensure the dependency array is correctly set for your use case

    // Define the formatDate function
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };


    // if (!invoices || !invoices.length) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboard-main">
                <Header />
                <div className="content-area">


                    <div className="invoice-container">
                        {/* Header Section */}
                        <header className="invoice-header">
                            {/* Company Logo */}
                            <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="invoice-logo" />
                            {/* Invoice Title */}
                            <h1>{t['INVOICE']}</h1> {/* Use the translated invoice title */}
                        </header>

                        {/* Client Details Section */}
                        <section className="client-details">
                            {/* Client Information */}

                            <div className='text-left'>
                                <strong>{t['INVOICE_TO']}</strong>
                                <p>{customerDetails?.first_name} {customerDetails?.sur_name}</p>

                                <div className='line-index'></div>

                                <strong>{t['CONTACT_PERSON']}</strong>

                                <p className='bottom-unset'>{t['PHONE']}: {customerDetails?.telephone}</p>
                                <p className='bottom-unset'>{t['EMAIL']}: {customerDetails?.email}</p>
                                <p>{t['ADDRESS']}: {customerDetails?.contact}</p>

                            </div>

                            {/* Invoice Information */}
                            <div className='text-left'>
                                <p className='bottom-unset'>{t['INVOICE_NO']}: {bookingDetails?.BookingID}</p>
                                <p>{t['INVOICE_DATE']}: {formatDate(bookingDetails?.date)}</p>

                                <div className='line-index'></div>
                                <strong> {t['PAYMENT_METHOD']} </strong>
                                <p className='bottom-unset'>{t['ORDER_ID']}: {bookingDetails?.BookingID}</p>
                                <p className='bottom-unset'>{t['ACCOUNT_NAME']}: {customerDetails?.first_name} {customerDetails?.sur_name} </p>
                                <p> {t['STATUS']}: {bookingDetails?.PaymentStatusName}</p>

                            </div>
                        </section>

                        {/* Invoice Items Section */}
                        <table className="invoice-items">
                            <thead>
                                <tr>                                    
                                    <th>#</th>
                                    <th>{t['DESCRIPTION']}</th>
                                    <th>{t['QUANTITY']}</th>
                                    <th>{t['SERVICE']}</th>
                                    <th>{t['PRICE']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetailsExtended.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ServiceName || 'N/A'}</td> {/* แสดง 'N/A' ถ้า ServiceName เป็น undefined */}
                                        <td>{`${new Date(item.ServiceDate).toLocaleDateString()} ${item.ServiceTime}`}</td> {/* รูปแบบวันที่และเวลา */}
                                        <td>{item.PriceType || 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceType เป็น undefined */}
                                        <td>{item.BookingDetailPrice ? `${item.BookingDetailPrice} €` : 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceValue เป็น undefined */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="row mt-4">

                            <div className="col-6">
                                <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="footer-logo" />
                                <p className='bottom-unset'>{clientDetails.name}</p>
                                <p>{clientDetails.address}</p>
                                <div className='line-index'></div>
                                <p>{clientDetails.notes}</p>
                            </div>

                            <div className="col-3">
                                <p className="grid-item sub-total-label">{t['SUB_TOTAL']}</p>
                                <p className="grid-item sub-total-label">{t['TAX']} ({fixedRate.tax}%)</p>
                                <p className="grid-item sub-total-label">{t['TOTAL_TRA']} </p>
                            </div>

                            <div className="col-3">
                                {/* <p className='grid-item'>{totalAmount.toFixed(2)}</p> */}
                                {/* <p className='grid-item'>{totalAmount.toFixed(2) - ((totalAmount * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p> */}

                                <p className='grid-item'>{totalAmount.toFixed(2)}</p>
                          
                                <p className='grid-item'>{((amountPaid * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p>
                               
                                <p className='grid-item'>{amountPaid.toFixed(2)}</p>
                            </div>
                        </div>

                        <div className='line-index'></div>
                        <div className='mt-2' >
                            <div className="legal-notes text-left">
                                <strong >{t['NOTES_IMPORTANTES']} :</strong>
                                <p> {t['NOTES_IMPORTANT']}</p>
                            </div>

                            <div className="row mt-4">
                                <div className="col-5">
                                    <p className="icon-text">
                                        <i className="pi pi-map-marker large-icon"></i>
                                        <span>{storeConstants.address}</span>
                                        <strong>{t['STORE_ADDRESS']}</strong>
                                    </p>
                                </div>

                                <div className="col-3">
                                    <p className="icon-text">
                                        <i className="pi pi-phone large-icon"></i>
                                        <span>{storeConstants.phone}</span>
                                    </p>
                                </div>

                                <div className="col-4">
                                    <p className="icon-text">
                                        <i className="pi pi-envelope large-icon"></i>
                                        <span>{storeConstants.email}</span>
                                    </p>
                                </div>
                            </div>

                            <div className='line-index'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



// Prop types validation
AdminInvoice.propTypes = {
  
    language: PropTypes.string, // Assuming language is a string prop
};

export default AdminInvoice;
