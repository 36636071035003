///var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/Sidebar.js
import React, { useState } from 'react';
import '../css/Sidebar.css'; // Ensure this path is correct for your CSS

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('dashboard');

  const userProfile = {
    name: 'AFAA PROFILE',
    role: 'Research and Development',
    image: 'https://afaa.website/s/3dc61b.svg', // Replace with path to user's image
  };

  // Handle image loading error
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents infinite callback loop
    e.target.src = 'fallback-image-path'; // Replace with a fallback image path
  };

  const menuItems = [
    { name: 'Dashboard', icon: 'fa-tachometer-alt', path: '/Dashboard' },
    { name: 'Payment Management', icon: 'fa-credit-card', path: '/payment' },    
    { name: 'Booking Management', icon: 'fa-calendar-check', path: '/booking' },
    { name: 'Queues Management', icon: 'fa-people-group', path: '/queues' },
    { name: 'Staff Management', icon: 'fa-users', path: '/Member' },
    { name: 'Spa Services Management', icon: 'fa-spa', path: '/services' },
    { name: 'Type Services', icon: 'fa-solid fa-layer-group', path: '/typeservices' },
    { name: 'Logout', icon: 'fa-sign-out-alt', path: '/logout' },
    // Add other menu items here
  ];

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    // Implement routing or state change as necessary
  };

  return (
    <aside className="sidebar">
       {/* User Profile Section */}
       <div className="user-profile">
       <img 
          src={userProfile.image} 
          alt={userProfile.name} 
          className="user-image"
          onError={handleImageError} // Handle the image loading error
        />
        <div className="user-info text-center">
          <h5 className="user-name ">{userProfile.name}</h5>
          <p className="user-role">{userProfile.role}</p>
        </div>
      </div>

      <nav className="menu">
        {menuItems.map(item => (
          <a
            key={item.name}
            href={item.path} // Replace with your routing logic
            className={`menu-item ${activeMenu === item.name ? 'active' : ''}`}
            onClick={() => handleMenuClick(item.name)}
          >
            <i className={`fas ${item.icon}`}></i> {item.name}
          </a>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
