///var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/Dashboard');
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message
    setShowModal(false); // Hide modal on new login attempt
  
    if (!email || !password) {
      setErrorMessage('Please enter both email and password.');
      setShowModal(true);
      return;
    }
  
    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setShowModal(true);
      return;
    }
  
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      setShowModal(true);
      return;
    }
  
    setIsSubmitting(true);
    try {
      const response = await fetch('https://py.testproject.work/login/api/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
  
      const data = await response.json();
  
      if (!response.ok || data.message !== 'OK') {
        setErrorMessage('Login failed: Incorrect email or password.');
        setShowModal(true); // Show modal on login failure
        setIsSubmitting(false);
        return;
      }
  
      localStorage.setItem('token', data.token);
      localStorage.setItem('user_ID', data.user);
      
      navigate('/Dashboard');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred.');
      setShowModal(true); // Show modal on fetch error
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>


      <div className="card shadow-lg p-4 mb-5 bg-white rounded" style={{ maxWidth: '400px' }}>
        <div className="card-body">
          <h3 className="text-center mb-4"> Management System for SPA Business</h3>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="staticEmail" className="form-label">Enter Email</label>
              <input
                type="email"
                className="form-control"
                id="staticEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">Enter Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="inputPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                />
                <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                  <i className={showPassword ? "fa-solid fa-eye-slash" : "fa-regular fa-eye"}></i>
                </button>
              </div>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  <><i className="fas fa-spinner fa-spin"></i> Logging in...</>
                ) : (
                  'LOGIN'
                )}
              </button>
            </div>
          </form>
          <p className="text-center mt-4">Developed by AFRA APACHE, Version 0.8 (spa)</p>
        </div>
      </div>

      {/* Bootstrap Modal for Error Message */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Login Error</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <p>{errorMessage}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add this div to create a backdrop effect when modal is displayed */}
      {showModal && <div className="modal-backdrop show"></div>}

    </div>

  );
}

export default Login;
