import React, { useState } from 'react';
import { OrderList } from 'primereact/orderlist';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const GalleryForm = ({
  previewImages,
  handleImageRemove, // This should be updated to use the setState method to refresh the OrderList
  onImageOrderChange,
  handleImagesChange,
}) => {
  // You may want to create a new state hook to trigger a re-render
  const [refresh, setRefresh] = useState(false);

  // Update the handleImageRemove function to use the new state
  const handleImageRemoveAndUpdate = (id) => {
    handleImageRemove(id);
    setRefresh(prev => !prev); // Toggle the refresh state to force re-render
  };

  const imageItemTemplate = (image, index) => {
    const imageUrl = image.isFromDatabase ? image.originalFileURLs : image.url;

    return (
      <div className="image-item" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <span>{index + 1}</span>
        <img src={imageUrl} alt={`Preview ${index + 1}`} style={{ width: '100px', height: 'auto' }} />
        <i 
          className="pi pi-trash" 
          style={{ cursor: 'pointer' }} 
          onClick={() => handleImageRemoveAndUpdate(image.id)}
        />
      </div>
    );
  };

  return (
    <div>
      <label htmlFor="galleryInput" className="form-label">Upload Images:</label>
      <input
        type="file"
        className="form-control"
        id="galleryInput"
        multiple
        onChange={handleImagesChange}
      />
      {previewImages.length > 0 && (
        <OrderList
          value={previewImages}
          itemTemplate={(image, index) => imageItemTemplate(image, index)}
          onChange={onImageOrderChange}
          listStyle={{ maxHeight: '400px', width: 'auto' }}
          dragdrop
        />
      )}
    </div>
  );
};

export default GalleryForm;
