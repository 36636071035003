//var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/ConfirmationModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import '../css/custom-modal.css';



const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel }) => {
  return (

   <Modal
  show={isOpen}
  onHide={onCancel}
  animation={false} // ปิดใช้งานแอนิเมชันมาตรฐานของ Bootstrap
  className="custom-modal-animation" // เพิ่มคลาสแอนิเมชันที่กำหนดเอง
>

      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
