// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/useFetchStatuses.js
import { useState, useEffect } from 'react';

const useFetchStatuses = (token) => {
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await fetch('https://py.testproject.work/register/api/status_list', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch statuses');
        }

        const data = await response.json();
        setStatuses(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchStatuses();
    }
  }, [token]);

  return { statuses, isLoading, error };
};

export default useFetchStatuses;
