// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/request_uuid.js
const requestUUID = async () => {
    const uuidRequestData = {
        table_id: "3",
        user_id: "123",
        ip_address: "192.168.1.1",
        timestamp: "2023-10-02 15:04:05",
        status: 1
    };

    const commonHeaders = {
        'Content-Type': 'application/json',
        // Ensure you have a valid token available in your application's state or storage
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    try {
        const response = await fetch('https://py.testproject.work/generate/api/uuid_create', {
            method: 'POST',
            headers: commonHeaders,
            body: JSON.stringify(uuidRequestData),
        });

        if (!response.ok) {
            // If the response is not ok, throw an error before attempting to parse JSON
            throw new Error(`Failed to fetch UUID, status: ${response.status}`);
        }

        const data = await response.json();
        return data.uuid; // Return UUID
    } catch (error) {
        console.error('Error fetching UUID:', error);
        throw error; // Rethrow error to be handled by caller
    }
};

export default requestUUID;
