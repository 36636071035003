// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/editor_textarea.js
import React, { useEffect, useState } from 'react';
import { Editor } from 'primereact/editor';

const TextEditor = ({ value, onContentChange }) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleTextChange = (e) => {
    const newValue = e.htmlValue;
    setText(newValue);
    if (onContentChange) {
      onContentChange(newValue);
    }
  };

  return (
    <Editor value={text} onTextChange={handleTextChange} style={{ height: '320px' }} />
  );
};

export default TextEditor;
