// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/ServicesTable.js
import React, { useEffect, useRef, useState } from 'react';

import $ from 'jquery';

import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import '../css/TypeServicesForm.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import ConfirmationModal from '../components/ConfirmationModal.js';


const ServicesTable = ({ servicesData, onEditService, onDelete, searchQuery, pageLength }) => {
  const dataTableRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleDeleteClick = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    onDelete([selectedServiceId]);
    setShowDeleteModal(false);
  };


  useEffect(() => {
    const dataTable = $(dataTableRef.current).DataTable({
      data: servicesData,
      pageLength: pageLength,
      lengthChange: false,
      columns: [
        {
          title: 'No.',
          data: null,
          render: (data, type, row, meta) => meta.row + meta.settings._iDisplayStart + 1
        },
        { data: 'ServiceID', title: 'Service ID' },
        { data: 'ServiceName', title: 'Service Name' },
        {
          data: 'ImageURL', 
          title: 'Image',        
          render: url => {
            return `<img src="${url}" alt="Service Image" style="width: 50px; height: auto;" 
                      onerror="this.onerror=null; this.src='https://afaa.website/s/9c25ab.svg';" />`;
          }
        },        
        { data: 'StatusName', title: 'Status' },
        { data: 'ServiceTypes_Name', title: 'Category' },
        {
          data: null,
          title: 'Actions',
          render: (data, type, row) => `
          <i class="fas fa-pen-to-square edit-icon btn-dark" data-id="${row.ServiceID}"></i>
          <i class="fas fa-trash delete-icon btn-dark" data-id="${row.ServiceID}"></i>
        `
        },
      ],
      ordering: true,
      dom: "<'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: "full_numbers",
      language: {
        paginate: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      initComplete: function () {
        this.api().on('draw', function () {
          const pagination = $('.dataTables_paginate');
          pagination.addClass('pagination-dark justify-content-end');
          pagination.find('a').addClass('page-link');
          pagination.find('span').addClass('page-item');
        });
      },
    });

    dataTable.search(searchQuery).draw();

    $(dataTableRef.current).on('click', '.delete-icon', function () {
      const serviceId = $(this).data('id');
      handleDeleteClick(serviceId); // Updated to call handleDeleteClick
    });

    // Handle edit icon click
    $(dataTableRef.current).on('click', '.edit-icon', function () {
      const serviceId = $(this).data('id');
      onEditService(serviceId);
    });

    return () => {
      if ($.fn.dataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }
    };
  }, [servicesData, searchQuery, pageLength, onEditService, onDelete]);


  return (
<div className="table-responsive">
      <table ref={dataTableRef} className="table"></table>

      <ConfirmationModal
        isOpen={showDeleteModal}
        message={"Are you sure you want to delete this service?"}
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteModal(false)}
      />

    </div>
  );
};

export default ServicesTable;
