// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Payment.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';


const Payment = () => {
  const [payments, setPayments] = useState([]); // State to hold the payments data
  const [globalFilter, setGlobalFilter] = useState(null); // State for the global filter

  useEffect(() => {
    const fetchPayment = async () => {
      const token = localStorage.getItem('token'); // Assume the token is stored in localStorage
      try {
        const response = await axios.get('https://py.testproject.work/payments/api/view?SortBy=TransactionID&SortOrder=DESC', {
          headers: {
            'Authorization': `Bearer ${token}`, // Use the token for authorization
          },
        });
        // Log and set the fetched data
        console.log('Fetched payments:', response.data);
        setPayments(response.data);
      } catch (error) {
        console.error('There was an error fetching the payment data:', error);
      }
    };

    fetchPayment(); // Trigger the data fetching
  }, []); // The empty dependency array ensures the effect runs only once on mount


  const bookingIDTemplate = (rowData) => {
    const handleInvoiceNavigation = () => {
        if (rowData.BookingID !== null && rowData.BookingID !== undefined) {
            // Store the BookingID in localStorage if it exists
            localStorage.setItem('localBookingReduxID', rowData.BookingID.toString());
            // Navigate to /payment/invoice in a new tab
            window.open(`/Receipt?BookingID=${rowData.BookingID}`, '_blank');
        } else {
            console.log("BookingID is null or undefined.");
            // Optionally handle the case where BookingID is null or undefined
        }
    };

    if (rowData.BookingID !== null && rowData.BookingID !== undefined) {
        return (
            <Button 
                label={rowData.BookingID.toString()} 
                icon="pi pi-search"
                className="p-button-raised p-button-rounded p-button-success" 
                onClick={handleInvoiceNavigation}
            />
        );
    } else {
        return (
            <span>N/A</span> // Or any other placeholder you prefer
        );
    }
};



  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">
        <Header searchQuery={globalFilter} setSearchQuery={setGlobalFilter} />
        <DataTable value={payments} paginator rows={10} globalFilter={globalFilter} header="Payment List">
          {/* Configure columns to match the structure of the fetched data */}         
          <Column field="TransactionID" header="Transaction ID" body={bookingIDTemplate} sortable filter filterPlaceholder="Search by ID"></Column>
          <Column field="TransactionID" header="Transaction ID" sortable filter></Column>
          <Column field="CustomerID" header="Customer ID" sortable filter></Column>
          <Column field="Amount" header="Amount" sortable filter></Column>
          <Column field="Currency" header="Currency" sortable filter></Column>
          <Column field="StatusPayment" header="Payment Status" sortable filter></Column>
          <Column field="TransactionDate" header="Date Transaction" sortable filter></Column>   
          <Column field="TransactionUpdate" header="Date Update Transaction" sortable filter></Column>   
          <Column field="Notes" header="Notes" sortable filter></Column>
          <Column field="ReferenceNumber" header="Reference PAYZEN" sortable filter></Column>
          {/* Add additional columns as needed */}
        </DataTable>
      </div>
    </div>
  );
}

export default Payment;
