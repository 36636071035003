// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/DataGraph.js
import React, { useEffect, useRef } from 'react';
import CanvasJS from '@canvasjs/charts'; // Import CanvasJS directly
import '../css/DataGraph.css';

const CanvasJSChart = CanvasJS.CanvasJSChart;

const DataGraph = () => {
  const chartContainerRef = useRef(null);

  // Static data for demonstration
  // Replace with bookingsData and ordersData as needed
  const bookingsData = [
    { date: "2024-01-01", count: 10 },
    { date: "2024-01-02", count: 7 },
    // ... other data points
  ];
  const ordersData = [
    { date: "2024-01-01", count: 5 },
    { date: "2024-01-02", count: 8 },
    // ... other data points
  ];

  useEffect(() => {
    if (chartContainerRef.current) {
      const chartOptions = {
        animationEnabled: true,
        theme: "light2",
        axisY: {
          title: "Count"
        },
        data: [
          {
            type: "area",
            name: "Daily Bookings",
            showInLegend: true,
            dataPoints: bookingsData.map(data => ({
              x: new Date(data.date),
              y: data.count
            }))
          },
          {
            type: "area",
            name: "Daily Orders",
            showInLegend: true,
            dataPoints: ordersData.map(data => ({
              x: new Date(data.date),
              y: data.count
            }))
          }
        ]
      };

      // Instantiate the chart inside useEffect using the CanvasJSChart constructor
      const chart = new CanvasJS.Chart(chartContainerRef.current, chartOptions);
      chart.render();

      // Cleanup function to destroy chart on unmount
      return () => chart && chart.destroy();
    }
  }, [bookingsData, ordersData]);

  return (
    <div className="data-graph-container">
      <h6 className="h6-title">Daily Bookings and Orders</h6>
     <div ref={chartContainerRef} className="chart-container" />
    {/* Rest of your JSX */}
    </div>
  );
};

export default DataGraph;
