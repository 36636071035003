// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // make sure to import axios

import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import '../css/DataQueues.scss';
import { Sidebar } from 'primereact/sidebar'; // Import Sidebar 

import { InputSwitch } from 'primereact/inputswitch';


const DataQueues = ({ selectedDate, onDateChange, refreshFlag }) => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null); // Ref to access the FullCalendar component

  const [sidebarVisible, setSidebarVisible] = useState(false); // State for Sidebar visibility
  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event details

  const [resources, setResources] = useState([]);

  const [interactionMode, setInteractionMode] = useState(true);

  const [eventDetails, setEventDetails] = useState({});


  // const eventDetails = {
  //   '2024-04-03': [
  //     { startTime: '09:00', endTime: '10:00', service: 'Consultation', customer: 'Alex', resourceId: '137', description: 'Consultation' },
  //     { startTime: '11:00', endTime: '13:30', service: 'Therapy Session', customer: 'Jamie', resourceId: '138', description: 'Therapy' },
  //   ],
  //   '2024-04-04': [
  //     { startTime: '10:00', endTime: '13:00', service: 'Routine Checkup', customer: 'Jordan', resourceId: '176', description: 'Routine' },
  //     { startTime: '13:00', endTime: '14:30', service: 'Consultation', customer: 'Morgan', resourceId: '177', description: 'Morgan' },
  //   ],
  //   '2024-04-07': [
  //     { startTime: '09:00', endTime: '10:00', service: 'Therapy Session', customer: 'Taylor', resourceId: '137', description: 'Consultation with Taylor1' },
  //     { startTime: '12:00', endTime: '13:30', service: 'Therapy Session', customer: 'Taylor', resourceId: '137', description: 'Consultation with Taylor2' },
  //     { startTime: '12:00', endTime: '13:00', service: 'Consultation', customer: 'Alex', resourceId: '138', description: 'Consultation with Alex' },
  //     { startTime: '17:00', endTime: '18:00', service: 'Consultation', customer: 'Test', resourceId: '176', description: 'Consultation with Test1' },
  //     { startTime: '12:00', endTime: '14:45', service: 'Consultation', customer: 'Test', resourceId: '176', description: 'Consultation with Test2' },
  //     { startTime: '10:30', endTime: '11:45', service: 'Consultation', customer: 'TestD', resourceId: '177', description: 'Consultation with Test3' },      
  //   ],

  //   "2024-04-08": [
  //     {
  //         "startTime": "11:00",
  //         "endTime": "12:00",
  //         "service": "Hammam and Choice of Massage",
  //         "customer": "NAN",
  //         "resourceId": "177",
  //         "description": "Chamaiphon SRIPONG"
  //     }
  // ]

  //   // ... other dates and events
  // };


  useEffect(() => {
    // Function to fetch users with position_ID = 2 and set resources
    const fetchResources = async () => {
      const userID = localStorage.getItem('user_ID'); // Get userID from localStorage
      const commonHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      try {
        const response = await fetch(`https://py.testproject.work/register/api/all_users?user_ID=${userID}&position_ID=2`, {
          headers: commonHeaders,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // Transform the data into the format expected by FullCalendar
        const calendarResources = data.map((user) => ({
          id: user.user_ID.toString(),
          title: user.first_name,
        }));

        setResources(calendarResources);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    fetchResources();
  }, []); // Empty dependency array to run only once on component mount


  useEffect(() => {
    const createEventObject = (event) => {

      const serviceDateTimeString = `${event.ServiceDate.split(' ')[1]} ${event.ServiceDate.split(' ')[2]} ${event.ServiceDate.split(' ')[3]} ${event.ServiceTime} GMT`;

      const eventDateTime = new Date(serviceDateTimeString);
      
      const formattedStartTime = event.ServiceTime.slice(0, 5);
      const formattedEndTime = event.EndTime.slice(0, 5);
  
      return {
        startTime: event.ServiceTime,
        endTime: event.EndTime,
        service: event.ServiceName,
        customer: `${event.EmployeeFirstName} ${event.EmployeeSurName}`, // Assuming you need both first and last name
        resourceId: event.EmployeeID.toString(),       
        extendedProps: {
          serviceDetail: `${event.PriceType}`,
          date: eventDateTime.toLocaleDateString(),
          time: formattedStartTime,
          endtime: formattedEndTime,
          customerInfo: `${event.CustomerFirstName} ${event.CustomerSurName}, ${event.CustomerTelephone}`,
          customerTel: `${event.CustomerTelephone}`,
          employeeInfo: `${event.EmployeeFirstName} ${event.EmployeeSurName}, ${event.EmployeeTelephone}`,
          price: `${event.PriceValue} ${event.currency}`,
          notes: `Notes: ${event.note || 'None'}`,
          serviceStatus: event.ServiceStatus,
          bookingType: event.BookingType
        },
      };
    };
  
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://py.testproject.work/booking/api/detail_calendarView', {
          params: {
            date_from: selectedDate,
            date_to: selectedDate,
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        //console.log('API response data:', response.data);
  
        const apiEventDetails = response.data.reduce((acc, event) => {
          const newEvent = createEventObject(event);
          const dateKey = new Date(event.ServiceDate).toISOString().split('T')[0];
  
          acc[dateKey] = acc[dateKey] || [];
          acc[dateKey].push(newEvent);
  
          return acc;
        }, {});
  
        setEventDetails(apiEventDetails);
        console.log('Transformed event details:', apiEventDetails);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
  
    fetchEvents();
  }, [selectedDate, refreshFlag]);
  
  useEffect(() => {
    if (eventDetails[selectedDate]) {
      const eventsForSelectedDate = eventDetails[selectedDate].map((event, index) => ({
        id: `${selectedDate}_${index}`,
        resourceId: event.resourceId,
        title: `${event.service}`,
        start: `${selectedDate}T${event.startTime}`,
        end: `${selectedDate}T${event.endTime}`,
        extendedProps: event.extendedProps,
      }));
  
      setEvents(eventsForSelectedDate);
    }
  }, [eventDetails, selectedDate]);
  



  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(selectedDate); // Navigate to the selected date
    }

  }, [selectedDate]); // This effect runs every time selectedDate changes



  // This is where you define how each event should be displayed in the calendar
// In the DataQueues component

const eventContent = (eventInfo) => {
  if (!eventInfo.event.extendedProps) return null; // Guard against undefined extendedProps

  const { extendedProps } = eventInfo.event;
  const priceStyle = extendedProps.serviceStatus === '1' ? 'color:green;' : 'color:red;';

  const htmlContent = `
    <div style="display:flex; flex-direction:column; align-items:start; font-size: small;">
      <div><i class="pi pi-clock" style="margin-right:4px;"></i>${extendedProps.time} - ${extendedProps.endtime}</div>
      <div style="${priceStyle}"><i class="pi pi-money-bill" style="margin-right:4px;"></i>${extendedProps.price}</div>
    </div>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
  );
};


  const formatEventDetailsForDisplay = (extendedProps) => {
    // Conditional styling for price based on ServiceStatus
  const priceStyle = extendedProps.serviceStatus === 1 ? 'color:green;' : 'color:red;';  
  const iconBookingType = extendedProps.bookingType === 'Solo' ? '<i class="pi pi-user"></i>' : '<i class="pi pi-user"></i><i class="pi pi-user"></i>';


    return `
    <p><i class="pi pi-clock"></i> Time: ${extendedProps.time} - <i class="pi pi-clock"></i> End time: ${extendedProps.endtime}\n</p>
    <p><i class="pi pi-user"></i> Cus. ${extendedProps.customerInfo}\n</p>
    <p><i class="pi pi-phone"></i> Phone: ${extendedProps.customerTel}\n</p>
    <p><i class="pi pi-calendar"></i> Date: ${extendedProps.date}\n</p>
    <p><i class="pi pi-briefcase"></i> Service: ${extendedProps.serviceDetail} ${iconBookingType} \n</p>
    <p><i class="pi pi-users"></i> Emp. ${extendedProps.employeeInfo}\n</p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Price: ${extendedProps.price} ${extendedProps.serviceStatus}</p>
    `;
  };

  const handleEventInteraction = (eventInfo) => {
    const eventProps = eventInfo.event.extendedProps;
    const detailsForDisplay = formatEventDetailsForDisplay(eventProps);
    setSelectedEvent({
      title: eventInfo.event.title,
      content: detailsForDisplay,
    });
    setSidebarVisible(true);
  };


  // Function to handle event click to open the sidebar
  const handleEventClick = (clickInfo) => {
    handleEventInteraction(clickInfo);
  };


  // Function to handle mouse enter to open the sidebar
  const handleEventMouseEnter = (mouseEnterInfo) => {
    handleEventInteraction(mouseEnterInfo);
  };

  // Function to handle date click to close the sidebar
  const handleDateSelect = (selectInfo) => {
    setSidebarVisible(false);
  };

  // Function to handle close button on the sidebar
  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const eventDidMount = (info) => {
    // Access the event's extendedProps for the description to display in the tooltip.
    const description = info.event.extendedProps.description || 'No description available';

    // Assign the description to the data-pr-tooltip attribute for the tooltip.
    info.el.setAttribute('data-pr-tooltip', description);
  };


  return (
    <>

      <div className="custom-switch-container">
        <InputSwitch checked={interactionMode} onChange={(e) => setInteractionMode(e.value)} />
        <label className="custom-switch-label">Hover</label>
      </div>

      <Sidebar
        visible={sidebarVisible}
        position="left"
        onHide={closeSidebar}
      >
        <p>{selectedEvent?.title}</p>
        <div dangerouslySetInnerHTML={{ __html: selectedEvent?.content }}></div>        
        {/* Add more content based on selectedEvent */}
      </Sidebar>

      <FullCalendar
        ref={calendarRef} // Assign the ref to the FullCalendar component
        plugins={[timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
        initialView="resourceTimeGridDay"
        events={events}
        eventDidMount={eventDidMount}

        editable={true} // Enable dragging and dropping of events
        eventConstraint={{
          startTime: '09:00', // Allow dragging from 9 AM
          endTime: '22:00',  // Allow dragging until 10 PM
        }}
        selectable={true} // Allows users to highlight multiple days or timeslots by clicking and dragging
        selectMirror={true} // Preview of the area being selected appears while dragging

        nowIndicator={true}
        eventClick={handleEventClick}
        dateClick={handleDateSelect}


        // resources={[
        //   { id: 'a', title: 'Employee A' },
        //   { id: 'b', title: 'Employee B' },
        //   { id: 'c', title: 'Employee C' },
        //   { id: 'd', title: 'Employee D' },
        // ]}

        resources={resources}

        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        allDaySlot={false} // Disables the all-day slot
        slotMinTime="09:00"
        slotMaxTime="22:00"

        customButtons={{
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('Clicked the custom button!');
            }
          }
        }}


        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'myCustomButton, timeGridDay,timeGridWeek'
        }}
        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }}

        initialDate={selectedDate} // Navigate to the selected date
        datesSet={(dateInfo) => onDateChange(dateInfo.startStr)}
        eventMouseEnter={interactionMode ? handleEventMouseEnter : null} s

        // Other props
        eventContent={eventContent}
      />

    </>
  );
};

export default DataQueues;
