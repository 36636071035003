///var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/Logout.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      const token = localStorage.getItem('token');

      // If token exists, call the logout API
      if (token) {
        try {
          await fetch('https://py.testproject.work/logout/api/checkout', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
          // Note: Handle any response or error as needed
        } catch (error) {
          console.error('Logout error:', error);
          // Optionally handle the error
        }
      }

      // Remove token and userData from localStorage regardless of API call result
      localStorage.removeItem('token');
      localStorage.removeItem('userData');

      // Redirect to login page
      navigate('/');
    };

    performLogout();
  }, [navigate]);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
}

export default Logout;
